import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../../components/bootstrap/text-field";
import Form from "formsy-react";
import {getFormData, setFormData} from "../../../components/manager/form";
import moment from "moment-mini";


export default function UserDetailsCardExpiry() {
  const history = useHistory();
  const {formatMessage} = useIntl();
  function validate(data) {
    let expiry = moment(`${data.cardExpiryMonth}${data.cardExpiryYear}`, "MMYY");
    return expiry.isValid() && (moment(expiry).isSameOrAfter(moment().startOf("month")));
  }
  async function handleOnSubmit(data) {
    const isValid = await validate(data);
    if (isValid) {
      setFormData(data);
      return history.push("/final");
    }
    // return refs.form.updateInputsWithError({
    //   cardExpiryMonth: "Invalid card expiry",
    //   cardExpiryYear: "Invalid card expiry",
    // });
  }
  function handleMonthChange(evt) {
    // if (refs.cardExpiryMonth.isValidValue(evt.target.value)) {
    //   return cardExpiryYear.focus();
    // }
    return undefined;
  }

  const data = getFormData();
  return (<Form className="section" onValidSubmit={handleOnSubmit}>
    <div>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="section-title" style={{textAlign: "center"}}>
              <FormattedMessage id="register.userDetails.cardExpiry.title" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto" sm={2} xs={12}>
            <TextField name="cardExpiryMonth"
              className="vw-form-field"
              mask={[/\d/, /\d/]}
              
              required
              placeholder={"MM"}
              pattern="[0-9]*"
              inputMode="numeric"
              autoComplete="off"
              validations={{
                minLength: 2,
                maxLength: 2,
                isNumeric: true,
                isExisty: true,
              }}
              validationErrors={{
                isExisty: (<FormattedMessage id="validation.errors.required.cardExpiryMonth" />),
                minLength: (<FormattedMessage id="validation.errors.minLength2" />),
                maxLength: (<FormattedMessage id="validation.errors.maxLength2" />),
                isNumeric: (<FormattedMessage id="validation.errors.isNumeric" />),
              }}
              value={data.cardExpiry}
              onChange={handleMonthChange}
            />
          </Col>
          <Col className="mr-auto" sm={2} xs={12}>
            <TextField name="cardExpiryYear"
              className="vw-form-field"
              mask={[/\d/, /\d/]}
              required
              placeholder={"YY"}
              pattern="[0-9]*"
              inputMode="numeric"
              autoComplete="off"
              validations={{
                minLength: 2,
                maxLength: 2,
                isNumeric: true,
                isExisty: true,
              }}
              validationErrors={{
                isExisty: (<FormattedMessage id="validation.errors.required.cardExpiryYear" />),
                minLength: (<FormattedMessage id="validation.errors.minLength2" />),
                maxLength: (<FormattedMessage id="validation.errors.maxLength2" />),
                isNumeric: (<FormattedMessage id="validation.errors.isNumeric" />),
              }}
              value={data.cardExpiry}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="submit">
              <span><FormattedMessage id="register.userDetails.keyPressEnter" /></span>
              <input  className="vw-button blue" type="submit" value={formatMessage({id: "register.userDetails.buttonConfirm"})} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </Form>);
}

