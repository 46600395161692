import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useHistory} from "react-router-dom";

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TextField from "../../../components/bootstrap/text-field";
import Form from "formsy-react";

import {getFormData, setFormData} from "../../../components/manager/form";



export default function UserDetailsCardName() {
  const history = useHistory();
  const {formatMessage} = useIntl();
  function handleOnSubmit(data) {
    setFormData(data);
    return history.push("/user/card-number");
  }
  const data = getFormData();
  return (<Form className="section" onValidSubmit={handleOnSubmit}>
    <div>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="section-title" style={{textAlign: "center"}}>
              <FormattedMessage id="register.userDetails.cardName.title" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto" sm={6} xs={12}>
            <TextField name="cardName"
              className="vw-form-field"
              
              required
              autoComplete="off"
              placeholder={formatMessage({id: "placeholders.cardName"})}
              validations={{
                maxLength: 50,
                isExisty: true,
                matchRegexp: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/,
              }}
              validationErrors={{
                isExisty: (<FormattedMessage id="validation.errors.required.cardName" />),
                maxLength: (<FormattedMessage id="validation.errors.maxLength50" />),
                matchRegexp: (<FormattedMessage id="validation.errors.required.cardName" />),
              }}
              value={data.cardName} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="submit">
              <span><FormattedMessage id="register.userDetails.keyPressEnter" /></span>
              <input  className="vw-button blue" type="submit" value={formatMessage({id: "register.userDetails.buttonConfirm"})} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </Form>);
}
