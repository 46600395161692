import React, { useState, useEffect } from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Link, useHistory} from "react-router-dom";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../../components/bootstrap/text-field";
import Form from "formsy-react";
import {getFormData, setFormData} from "../../../components/manager/form";
import {getCountryByCode} from "../../../utils/countries";
import DataHelper from "../../../utils/dataHelper";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { resendVerificationMutation, validateMobileVerificationMutation, validateMobileVerificationQuery } from "../../../components/logic/mobile";



export default function UserDetailsVerification() {
  const [seconds, setSeconds] = useState(30);
  const [processing, setProcessing] = useState(false);
  const [valid, setValid] = useState(false);
  const [interval, setInterval] = useState(null);
  const [error, setError] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const history = useHistory();
  const {formatMessage} = useIntl();
  const data = getFormData();
  const {mobileCountry, mobileNumber, verification} = data;

  const [resendMutation] = useMutation(resendVerificationMutation);
  const [validateMutation] = useMutation(validateMobileVerificationMutation);

  function handleTimer() {
    const itval = setInterval(() => {
      const s = seconds - 1;
      if (seconds < 1) {
        clearInterval(itval);
        setSeconds(0);
        setInterval(null);
      } else {
        return setSeconds(s);
      }
    }, 1000);
  }
  async function handleOnInvalidSubmit() {
    if (seconds === 0) {
      await setProcessing(true);
      const data = getFormData();
      const {mobileCountry, mobileNumber} = data;
      const response = await resendMutation({variables: {mobileCountry, mobileNumber}});
      if (response.data.resendVerification) {
        setProcessing(false);
        setSeconds(30);
        return handleTimer();
      }
    }
    return undefined;
  }

  async function handleOnSubmit(values) {
    setProcessing(true);
    setFormData(values);
    const data = getFormData();
    const {mobileNumber} = data;
    const response = await validateMutation({variables: {mobileNumber, code: data.verification}});
    console.log("response", {response, data});
    if (response?.data?.classMethods?.MobileVerification?.validateMobileVerification) {
      return history.push("/user/card");
    }

    setProcessing(false);
    await setError("Invalid Verification Code");
    // return refs.form.updateInputsWithError({
    //   verification: "Invalid verification code",
    // });
  }

  function handleResetVerification() {
    return history.push("/user/phone");
  }

  function handleValid() {
    setValid(true);
  }

  function handleInvalid() {
    setValid(false);
  }

  function handleOnTextChange(e, a) {
    // console.log("text change", e, a)
    setVerificationCode(e.target.value);

  }

  useEffect(() => {
    return () => {
      if(interval) {
        clearInterval(interval)
      }
    }
  }, [interval]);
  useEffect(() => {
    handleTimer();
  }, []);
  const selectedCountry = getCountryByCode(mobileCountry || "au");

  let submissionText;
  if (seconds > 0) {
    submissionText = "register.mobile.countdown";
  } else {
    submissionText = "register.mobile.countdown.finish";
  }
  return (
    <Form className="section" onValidSubmit={handleOnSubmit} onInvalidSubmit={handleOnInvalidSubmit} noValidate onValid={handleValid} onInvalid={handleInvalid}>
      <div>
        <Container>
          <Row style={{marginBottom: "1em"}}>
            <Col xs={12} className="section-title" style={{textAlign: "center"}}>
              <FormattedMessage
                id="register.userDetails.verification.title"
                values={{
                  dialCode: selectedCountry.dialCode,
                  mobileNo: DataHelper.cleanMobile(mobileNumber),
                }}
              />
            </Col>
          </Row>
          {error && <Row>
            <Col xs={12} sm={6} className="mx-auto">
              <div className="alert alert-danger my-1">
                {error}
              </div>
            </Col>
          </Row>}
          <Row>
            <Col className="mx-auto" sm={6} xs={12}>
              <TextField
                className="vw-form-field input-radius center-placeholder"
                inputStyle={{width: "100%"}}
                onChange={handleOnTextChange}
                required
                placeholder={formatMessage({id: "placeholders.mobileNumberVerification"})}
                validations={{
                  maxLength: 15,
                  isExisty: true,
                }}
                pattern="[0-9]*"
                inputMode="numeric"
                autoComplete="off"
                validationErrors={{
                  isExisty: (<FormattedMessage id="validation.errors.required.mobileNumberVerification" />),
                  maxLength: (<FormattedMessage id="validation.errors.maxLength50" />),
                }}
                name="verification"
                disabled={processing}
                value={data.verification}
              />
            </Col>
          </Row>
          <Row className="align-items-end">
            <Col xs={12} sm={6} className="verification-bad">

            <span></span>
              <Link to="/user/phone">
                <div className="vn-label" style={{color: "#444444"}}>

                  <FormattedMessage id="register.userDetails.verification.bad" />
                </div>
              </Link>
            </Col>
            <Col xs={12} sm={6} >
              <div className={"submit single"}>
                <input type="submit" className="vw-button orange next-btn"
                  value={formatMessage({id: seconds === 0  && (verificationCode || "").length === 0 ? "register.userDetails.buttonResend" : "register.userDetails.buttonConfirm"})}
                  disabled={(!valid && seconds > 0) || processing}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Form>
  );
}
