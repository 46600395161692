import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useHistory} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TextField from "../../../components/bootstrap/text-field";
import Form from "formsy-react";
import moment from "moment-mini";

import {getFormData, setFormData} from "../../../components/manager/form";
import {useAsyncSetState} from "use-async-setstate";



const UserDetailsCardName = () => {
  const history = useHistory();
  const {formatMessage} = useIntl();
  const [error, setError] = useAsyncSetState("");

  const validate = (data) => {
    let expiry = moment(`${data.cardExpiryMonth}${data.cardExpiryYear}`, "MMYY");
    return Promise.resolve(expiry.isValid() && (moment(expiry).isSameOrAfter(moment().startOf("month"))));
  };

  const handleOnSubmit = async (data) => {
    const isValid = await validate(data);
    if (isValid) {
      setFormData(data);
      return history.push("/final");
    }

    return setError("Invalid Card Expiry");
  };
  const data = getFormData();

  return (
    <Form className="section" onValidSubmit={handleOnSubmit}>
      <div>
        <Container>
          <Row>
            <Col xs={12}>
              <div className="section-title" style={{textAlign: "center"}}>
                {"One last step. What are your card details?"}
              </div>
            </Col>
          </Row>
          {error && <Row>
            <Col xs={12}>
              <div className="alert alert-danger">
                {error}
              </div>
            </Col>
          </Row>}
          <Row>
            <Col className="mx-auto" sm={12} xs={12}>
              <TextField name="cardNumber"
                className="vw-form-field center-placeholder input-radius mb-7"
                required
                placeholder={formatMessage({id: "placeholders.cardNumber"})}
                pattern="[0-9]*"
                inputMode="numeric"
                autoComplete="off"
                validations={{
                  maxLength: 16,
                  minLength: 16,
                  isNumeric: true,
                  isExisty: true,
                }}
                validationErrors={{
                  isExisty: (<FormattedMessage id="validation.errors.required.cardNumber" />),
                  minLength: (<FormattedMessage id="validation.errors.minLength16" />),
                  maxLength: (<FormattedMessage id="validation.errors.minLength16" />),
                  isNumeric: (<FormattedMessage id="validation.errors.isNumeric" />),
                }}
                value={data.cardNumber}
              />
            </Col>
            <Col className="mx-auto" sm={12} xs={12}>
              <TextField name="cardName"
                className="vw-form-field center-placeholder input-radius mb-7"
                required
                autoComplete="off"
                placeholder={formatMessage({id: "placeholders.cardName"})}
                validations={{
                  maxLength: 50,
                }}
                validationErrors={{
                  maxLength: (<FormattedMessage id="validation.errors.maxLength50" />),
                }}
                value={data.cardName} />
            </Col>
            <Col className="mx-auto" sm={12} xs={12}>
              <TextField name="cardCCV"
                className="vw-form-field center-placeholder input-radius mb-7"
                required
                autoComplete="off"
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder={formatMessage({id: "placeholders.cardCCV"})}
                validations={{
                  maxLength: 3,
                  isNumeric: true,
                  isExisty: true,
                }}
                validationErrors={{
                  isExisty: (<FormattedMessage id="validation.errors.required.cardCCV" />),
                  maxLength: (<FormattedMessage id="validation.errors.maxLength3" />),
                  isNumeric: (<FormattedMessage id="validation.errors.isNumeric" />),
                }}
                value={data.cardCCV} />
            </Col>
            <Col className="mx-auto" sm={12} xs={12}>
              <div className="valid-label mb-7">
                {"Valid Until"}
              </div>
            </Col>
            <Col className="mx-auto" sm={6} xs={4}>
              <TextField name="cardExpiryMonth"
                className="vw-form-field center-placeholder input-radius mb-7"
                mask={[/\d/, /\d/]}
                required
                placeholder={"MM"}
                pattern="[0-9]*"
                inputMode="numeric"
                autoComplete="off"
                validations={{
                  minLength: 2,
                  maxLength: 2,
                  isNumeric: true,
                  isExisty: true,
                  invalidMonthValue: (__, value) => {
                    return value <= 12 && value > 0; // Checks if valid month
                  },
                  isExpired: (values, value) => {
                    const currentMonth = Number(new Date()
                      .getMonth()
                      .toString()
                      .slice(-2)) + 1;
                    const currentYear = new Date()
                      .getFullYear()
                      .toString()
                      .slice(-2);

                    return (
                      // Validates if card is expired
                      values.cardExpiryYear === undefined ||
                      values.cardExpiryYear.toString().trim() === "" ||
                      Number(values.cardExpiryYear) > Number(currentYear) ||
                      (Number(
                        currentMonth > 10 ? `0${currentMonth}` : currentMonth
                      ) <= Number(value)) && Number(values.cardExpiryYear) >= Number(currentYear)
                    );
                  },
                }}
                validationErrors={{
                  isExisty: (<FormattedMessage id="validation.errors.required.cardExpiryMonth" />),
                  minLength: (<FormattedMessage id="validation.errors.minLength2" />),
                  maxLength: (<FormattedMessage id="validation.errors.maxLength2" />),
                  isNumeric: (<FormattedMessage id="validation.errors.isNumeric" />),
                  invalidMonthValue: "Cannot input invalid month",
                  isExpired: "Card already expired",
                }}
                value={data.cardExpiry}
              />
            </Col>
            <Col className="mx-auto" sm={6} xs={4}>
              <TextField name="cardExpiryYear"
                className="vw-form-field center-placeholder input-radius mb-7"
                mask={[/\d/, /\d/]}
                required
                placeholder={"YY"}
                pattern="[0-9]*"
                inputMode="numeric"
                autoComplete="off"
                validations={{
                  minLength: 2,
                  maxLength: 2,
                  isNumeric: true,
                  isExisty: true,
                }}
                validationErrors={{
                  isExisty: (<FormattedMessage id="validation.errors.required.cardExpiryYear" />),
                  minLength: (<FormattedMessage id="validation.errors.minLength2" />),
                  maxLength: (<FormattedMessage id="validation.errors.maxLength2" />),
                  isNumeric: (<FormattedMessage id="validation.errors.isNumeric" />),
                }}
                value={data.cardExpiry}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div>
                <input className="vw-button orange next-btn center" type="submit" value={formatMessage({id: "register.userDetails.buttonConfirm"})} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Form>
  );
};

export default UserDetailsCardName;
