import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useHistory} from "react-router-dom";

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TextField from "../../../components/bootstrap/text-field";
import Form from "formsy-react";

import {getFormData, setFormData} from "../../../components/manager/form";


// import LockWhiteImage from "content/font-awesome/light/lock-white.svg";


export default function UserDetailsCardNumber() {

  const history = useHistory();
  const {formatMessage} = useIntl();
  function handleOnSubmit(data) {
    setFormData(data);
    return history.push("/user/card-ccv");
  }
  const data = getFormData();
  return (<Form className="section" onValidSubmit={handleOnSubmit}>
    <div>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="section-title" style={{textAlign: "center"}}>
              <FormattedMessage id="register.userDetails.cardNumber.title" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto" sm={6} xs={12}>
            <TextField name="cardNumber"
              className="vw-form-field"
              required
              placeholder={formatMessage({id: "placeholders.cardNumber"})}
              pattern="[0-9]*"
              inputMode="numeric"
              autoComplete="off"
              validations={{
                maxLength: 16,
                minLength: 16,
                isNumeric: true,
                isExisty: true,
              }}
              validationErrors={{
                isExisty: (<FormattedMessage id="validation.errors.required.cardNumber" />),
                minLength: (<FormattedMessage id="validation.errors.minLength16" />),
                maxLength: (<FormattedMessage id="validation.errors.minLength16" />),
                isNumeric: (<FormattedMessage id="validation.errors.isNumeric" />),
              }}
              value={data.cardNumber} />
              {/* <div className="lock-icon" style={{backgroundImage: `url(${LockWhiteImage})`}}/> */}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="submit">
              <span><FormattedMessage id="register.userDetails.keyPressEnter" /></span>
              <input className="vw-button blue" type="submit" value={formatMessage({id: "register.userDetails.buttonConfirm"})} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </Form>);
}
