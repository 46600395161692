import React from "react";
import {Link, useHistory} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {Container, Col, Row} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useMutation, useQuery} from "@apollo/client";

import {
  getFormData,
  setFormData,
  clearFormData,
} from "../../components/manager/form";
import Panel from "./panel";
import {getPlanImage, getPlanSpeedLabel} from "./plan";
import {
  getSiteInfoQuery,
  getSiteInfoResult,
  purchaseAndCreateUserMutation,
} from "../../components/logic/purchase";
import {
  getProductsQuery,
  getProductsResult,
} from "../../components/logic/products";
import Terms from "../../components/portal/terms";
import PrivacyPolicy from "../../components/portal/privacy-policy";
import {CIS_LINK, NON_INTERNET_PRODUCT_TYPES_TO_SHOW} from "../../utils/constant";
import { formatLocation } from "../../components/logic/address";

const Final = (props) => {
  const data = getFormData();
  const cisLink = data?.building?.cisLink || CIS_LINK;

  const history = useHistory();
  const {formatMessage} = useIntl();
  const [submittingForm, setSubmittingForm] = useAsyncSetState(false);
  const [acknowledgeChecked, setAcknowledgeChecked] = useAsyncSetState(false);
  const [tncChecked, setTncChecked] = useAsyncSetState(false);
  const [showTerm, setTerm] = useAsyncSetState(false);
  const [showPolicy, setPolicy] = useAsyncSetState(false);
  const [purchaseMutation] = useMutation(purchaseAndCreateUserMutation);
  const siteInfoQuery = useQuery(getSiteInfoQuery);
  const productsQuery = useQuery(getProductsQuery, {
    variables: {
      buildingCode: data?.building?.code,
      voucherCode: data.voucherCode,
      showHidden: true,
    },
  });

  if (productsQuery.loading || siteInfoQuery.loading) {
    return <React.Fragment />;
  }
  const siteInfo = getSiteInfoResult(siteInfoQuery);
  const products = getProductsResult(productsQuery);
  const handleAcknowledge = (e, checked) => {
    return setAcknowledgeChecked(checked);
  };

  const handleAcceptTnc = (e, checked) => {
    return setTncChecked(checked);
  };

  // const handleOnSubmit = async (data) => {
  //   setFormData(data);
  //   return handleValidateFormCatpcha();
  // };

  const handleValidateFormCatpcha = async () => {
    setSubmittingForm(true);
    return handleFinalFormSubmit();
  };

  const handleFinalFormSubmit = async () => {
    try {
      const data = getFormData();
      const {
        firstName,
        lastName,
        email,
        mobileNumber,
        mobileCountry,
        voucherCode,
        verification,
        selectedProduct,
        cardNumber,
        cardCCV,
        cardName,
        cardExpiryMonth,
        cardExpiryYear,
        countryId,
        addons,
        shippingAddress,
        homeAddress,
        hardware
      } = data;

      const {unitNo, streetNo, address, suburb, state, postCode} =
        homeAddress;
      const ewayCryptKey = siteInfo.auEwayClientKey;
      let addresses = [];
      if (hardware) {
        addresses.push(Object.assign({type: "Shipping"}, shippingAddress));
      }
      const variables = {
        firstName,
        lastName,
        email,
        mobileNumber,
        mobileCountry,
        unitNo,
        voucherCode,
        streetNo,
        address,
        suburb,
        state,
        postCode,
        buildingCode: "",
        verificationCode: verification,
        products: [selectedProduct, ...addons].map((p) => ({id: p.id, ...p.orderItemOpts})),
        countryId,
        addresses,
        cardNumber: window.eCrypt.encryptValue(cardNumber, ewayCryptKey),
        cardCCV: window.eCrypt.encryptValue(cardCCV, ewayCryptKey),
        cardName: cardName,
        cardExpiry: `${cardExpiryMonth}${cardExpiryYear}`,
      };

      const response = await purchaseMutation({
        variables,
      });

      clearFormData();
      setFormData({
        response: {
          data: response.data.classMethods.User.purchaseAndCreateUser,
        },
      });
      return history.push("/success");
    } catch (err) {
      console.log("ERROR >>> ", err);
      setFormData({response: err});
      return history.push("/fail");
    }
  };

  const {
    selectedProduct = {},
    addons = [],
    shippingAddress,
    homeAddress = {},
    hardware,
  } = data;

  const setupProducts = products.filter(
    (pp) => pp.required && pp.id !== selectedProduct.id && !pp.onlyNewConnection
  );
  let newConnectionProducts = [];
  if (data?.isNewConnection) {
    newConnectionProducts = products.filter(
      (pp) => pp.id !== selectedProduct.id && pp.onlyNewConnection
    );
  }

  let connectionFee = newConnectionProducts.reduce(
    (c, ncp) => c + parseFloat(ncp.value),
    0
  );
  let setupFee = setupProducts.reduce((c, ncp) => c + parseFloat(ncp.value), 0);
  const total =
    selectedProduct.value +
    connectionFee +
    setupFee +
    (addons || []).reduce((o, p) => p.value + o, 0);
  const perMonth =
    selectedProduct.value +
    addons
      .filter((p) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.indexOf(p.type) > -1)
      .reduce((o, p) => p.value + o, 0);

  const originalPerMonth =
    Math.round((selectedProduct.originalValue || selectedProduct.value +
    addons
      .filter((p) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.indexOf(p.type) > -1)
      .reduce((o, p) => (p.originalValue || p.value) + o, 0)) * 100) / 100;

  if (submittingForm) {
    return (
      <Panel className="final-processing">
        <div className="result-screen">
          <Container>
            <Row className="justify-content-center">
              <Col className="mx-auto" xs={12} sm={6}>
                <div className="inner">
                  <h2>
                    <FormattedMessage id={"register.final.processing"} />
                    <div>
                      <div className="rotating-loader mx-auto my-3" />
                    </div>
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Panel>
    );
  }

  const product = data.selectedProduct || {};

  return (
    <Panel className="final">
      {showTerm && <Terms onClose={() => setTerm(false)} />}
      {showPolicy && <PrivacyPolicy onClose={() => setPolicy(false)} />}
      <div className="display">
        <div className="title">
          <FormattedMessage id="register.details.title" />
        </div>
        <Container>
          <Row className="review-header">
            <Col xs={12}>
              <FormattedMessage id={"register.final.review"} />
            </Col>
          </Row>
          <Row>
            <Col xs="auto" lg={10} className="mx-auto">
              <div className="product-display">
                <Container fluid>
                  <Row>
                    <Col className="mx-auto" xs="auto">
                      <div className="pd-title">
                        {`${selectedProduct.name} Plan`}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mx-auto" xs="auto">
                      <div className="image">
                        {getPlanImage(selectedProduct.name)}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mx-auto" xs="auto">
                      <div
                        className="price"
                        style={{
                          textDecoration: selectedProduct.originalValue
                            ? "line-through"
                            : undefined,
                        }}
                      >
                        {`$${
                          selectedProduct.originalValue
                            ? selectedProduct.originalValue
                            : selectedProduct.value
                        }/month`}
                        {(selectedProduct || {}).voucherName && (
                          <div className="product-voucher">
                            {`$${selectedProduct.value} ${selectedProduct.voucherName}`}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mx-auto" xs="auto">
                      <div className="description">
                        {selectedProduct.description}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mx-auto" xs="auto">
                      <div className="evening-speed">
                        {`Typical Evening Speed: ${getPlanSpeedLabel(
                          selectedProduct.name
                        )} Mbps`}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} className="mx-auto">
              <h3 className="final-header">{"Personal Information"}</h3>
            </Col>
          </Row>
          <Row>
            <Col className={"mx-auto"} lg={10}>
              <Container fluid>
                <Row>
                  <Col lg={4} className={"col-display"}>
                    <div className={"label"}>
                      <FormattedMessage id={"register.final.name"} />
                      <span className="edit">
                        <Link to="/user/name">
                          {" ("}
                          <FormattedMessage id={"register.final.edit"} />
                          {")"}
                        </Link>
                      </span>
                    </div>
                    <span className={"value"}>
                      {`${data.firstName} ${data.lastName}`}
                    </span>
                  </Col>
                  <Col lg={5} className={"col-display"}>
                    <div className={"label"}>
                      <FormattedMessage id={"register.final.email"} />
                      <span className="edit">
                        <Link to="/user/email">
                          {" ("}
                          <FormattedMessage id={"register.final.edit"} />
                          {")"}
                        </Link>
                      </span>
                    </div>
                    <span className={"value"}>{`${data.email}`}</span>
                  </Col>
                  <Col lg={3} className={"col-display"}>
                    <div className={"label"}>
                      <FormattedMessage id={"register.final.mobileNumber"} />
                      <span className="edit">
                        <Link to="/user/phone">
                          {" ("}
                          <FormattedMessage id={"register.final.edit"} />
                          {")"}
                        </Link>
                      </span>
                    </div>
                    <span className={"value"}>{data.mobileNumber}</span>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg={12} className={"col-display"}>
                    <div className={"label"}>
                      <FormattedMessage id={"register.final.address"} />
                      <span className="edit">
                        <Link to="/user/address">
                          {" ("}
                          <FormattedMessage id={"register.final.edit"} />
                          {")"}
                        </Link>
                      </span>
                    </div>
                    <span className={"value"}>
                      {formatLocation(homeAddress)}
                    </span>
                  </Col>
                </Row>
                <hr />
                {shippingAddress && hardware && (
                  <React.Fragment>
                    <Row>
                      <Col lg={12} className={"col-display"}>
                        <div className={"label"}>
                          <FormattedMessage
                            id={"register.final.shipping-address"}
                          />
                          <span className="edit">
                            <Link to="/user/shipping-address">
                              {" ("}
                              <FormattedMessage id={"register.final.edit"} />
                              {")"}
                            </Link>
                          </span>
                        </div>
                        <span className={"value"}>
                          {formatLocation(shippingAddress)}
                        </span>
                      </Col>
                    </Row>
                    <hr />
                  </React.Fragment>
                )}
                {data.cardNumber && (
                  <React.Fragment>
                    <Row>
                      <Col lg={4} className={"col-display"}>
                        <div className={"label"}>
                          <FormattedMessage id={"register.final.cardNumber"} />
                          <span className="edit">
                            <Link to="/user/card">
                              {" ("}
                              <FormattedMessage id={"register.final.edit"} />
                              {")"}
                            </Link>
                          </span>
                        </div>
                        <span className={"value"}>
                          {`XXXX XXXX XXXX ${data.cardNumber.substring(
                            12,
                            data.cardNumber.length
                          )}`}
                        </span>
                      </Col>
                      <Col lg={4} className={"col-display"}>
                        <div className={"label"}>
                          <FormattedMessage id={"register.final.cardName"} />
                          <span className="edit">
                            <Link to="/user/card">
                              {" ("}
                              <FormattedMessage id={"register.final.edit"} />
                              {")"}
                            </Link>
                          </span>
                        </div>
                        <span className={"value"}>{`${data.cardName}`}</span>
                      </Col>
                      <Col lg={2} className={"col-display"}>
                        <div className={"label"}>
                          <FormattedMessage id={"register.final.cardExpiry"} />
                          <span className="edit">
                            <Link to="/user/card">
                              {" ("}
                              <FormattedMessage id={"register.final.edit"} />
                              {")"}
                            </Link>
                          </span>
                        </div>
                        <span className={"value"}>
                          {`${data.cardExpiryMonth}/${data.cardExpiryYear}`}
                        </span>
                      </Col>
                      <Col lg={2} className={"col-display"}>
                        <div className={"label"}>
                          <FormattedMessage id={"register.final.cardCCV"} />
                          <span className="edit">
                            <Link to="/user/card">
                              {" ("}
                              <FormattedMessage id={"register.final.edit"} />
                              {")"}
                            </Link>
                          </span>
                        </div>
                        <span className={"value"}>{"XXX"}</span>
                      </Col>
                    </Row>
                    <hr />
                  </React.Fragment>
                )}
              </Container>
            </Col>
          </Row>
          <Row>
            <Col lg={10} className={"mx-auto"}>
              <Container fluid>
                {(addons || []).filter((a) => a.type !== "hardware").length > 0 && (
                  <Row>
                    <Col xs="auto" className={"mx-auto"}>
                      <div className={"label"}>
                        <h3 className="final-header">
                          <FormattedMessage id={"register.final.addons"} />
                        </h3>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="preview-addons">
                  {(addons || [])
                    .filter((a) => a.type !== "hardware")
                    .map((a) => {
                      return (
                        <Col lg={6} key={a}>
                          <Container fluid className="no-gutters">
                            <Row>
                              <Col className="name" xs="auto">
                                {a.name}
                                {a.extraInfo && a.extraInfo !== "" && (
                                  <OverlayTrigger
                                    placement={"right"}
                                    overlay={
                                      <Tooltip>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: a.extraInfo,
                                          }}
                                        />
                                      </Tooltip>
                                    }
                                  >
                                    <i className="far fa-question-circle" />
                                  </OverlayTrigger>
                                )}
                              </Col>
                              <Col xs="auto" className="ml-auto">
                                <span className="value">
                                  {`+${a.value}${
                                    a.once ? " once off" : "/Month"
                                  } `}
                                </span>
                                <Link to="/review">
                                  <span className="edit">{"(edit)"}</span>
                                </Link>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      );
                    })}
                </Row>
                {(addons || []).filter((a) => a.type === "hardware").length > 0 && (
                  <Row>
                    <Col xs="auto" className={"mx-auto"}>
                      <div className={"label"}>
                        <h3 className="final-header">
                          <FormattedMessage
                            id={"register.final.optional.hardware"}
                          />
                        </h3>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="preview-addons">
                  {(addons || [])
                    .filter((a) => a.type === "hardware")
                    .map((a) => {
                      return (
                        <Col lg={6} key={a.id}>
                          <Container fluid className="no-gutters">
                            <Row>
                              <Col className="name" xs="auto">
                                {a.name}
                                {a.extraInfo && a.extraInfo !== "" && (
                                  <OverlayTrigger
                                    placement={"right"}
                                    overlay={
                                      <Tooltip>
                                        <div
                                          // eslint-disable-next-line react/no-danger
                                          dangerouslySetInnerHTML={{
                                            __html: a.extraInfo,
                                          }}
                                        />
                                      </Tooltip>
                                    }
                                  >
                                    <i className="far fa-question-circle" />
                                  </OverlayTrigger>
                                )}
                              </Col>
                              <Col xs="auto" className="ml-auto">
                                <span className="value">
                                  {`+${a.value}${
                                    a.once ? " once off" : "/Month"
                                  } `}
                                </span>
                                <Link to="/review">
                                  <span className="edit">{"(edit)"}</span>
                                </Link>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      );
                    })}
                </Row>
              </Container>
            </Col>
          </Row>

          <Row>
            <Col xs="auto" className="mx-auto">
              <h3 className="final-header">{"Summary"}</h3>
            </Col>
          </Row>
          <Row>
            <Col lg={10} className="mx-auto">
              <Container fluid>
                <Row>
                  <Col>
                    <div className="review-costs">
                      <Container fluid>
                        {connectionFee > 0 && (
                          <Row className="totals justify-content-center align-items-center">
                            <Col xs={12}>
                              <div className="header">{"New Developments Charge (Once off)"}</div>
                            </Col>
                            <Col xs={12}>
                              <div className="value">{`$${connectionFee}`}</div>
                            </Col>
                          </Row>
                        )}
                        {total !== perMonth && (
                          <Row className="totals justify-content-center align-items-center">
                            <Col xs={12}>
                              <div className="header">{"Initial Payment (Including First Month)"}</div>
                            </Col>
                            <Col xs={12}>
                              <div className="value">{`$${total}`}</div>
                            </Col>
                          </Row>
                        )}
                        <Row className="totals justify-content-center align-items-center">
                          <Col xs={12}>
                            <div className="header">{"Total Per Month"}</div>
                          </Col>
                          <Col xs={12}>
                            <div className="value">{`$${perMonth}/month`}</div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col
              className="col-accepttnc mx-auto"
              xs={12}
              sm={10}
              style={{marginTop: 20}}
            >
              <Container fluid>
                <Row>
                  <Col xs={12}>
                    <div className="vw-checkbox">
                      <Col xs="auto p-0">
                        <i
                          onClick={(e) =>
                            handleAcknowledge(e, !acknowledgeChecked)
                          }
                          style={{fontSize: 35}}
                          className={`mr-2 ${
                            acknowledgeChecked
                              ? "far fa-check-square"
                              : "far fa-square"
                          }`}
                        />
                      </Col>
                      <Col className="p-0">
                        {(() => {
                          let ack = "";
                          if (connectionFee > 0) {
                            ack += `I acknowledge I will be charged $${connectionFee} for the New Developments Charge in the first month in addition to my plan charge/s. `;
                          }
                          if (product.voucherName && product.voucherUses > 1) {
                            ack += `I acknowledge that I will be charged plan charges of $${perMonth} per month for the first ${product.voucherUses} month/s and then $${originalPerMonth} per month, until cancelled. (First month: $${total}, next ${product.voucherUses - 1} month/s: $${perMonth}, thereafter: $${originalPerMonth})`;
                          } else if (product.voucherName && product.voucherUses === 1) {
                            ack += `I acknowledge that I will be charged plan charges of $${perMonth} per month for the first month and then $${originalPerMonth} per month, until cancelled. (First month: $${total}, thereafter: $${originalPerMonth})`;
                          } else {
                            ack += `I acknowledge that I will be charged plan charges of $${perMonth} per month, until cancelled. (First month: $${total}, thereafter: $${originalPerMonth})`;
                          }
                          return ack;
                        })()}
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="vw-checkbox tncs-link">
                      <i
                        onClick={(e) => handleAcceptTnc(e, !tncChecked)}
                        style={{fontSize: 35}}
                        className={`mr-2 ${
                          tncChecked ? "far fa-check-square" : "far fa-square"
                        }`}
                      />
                      <FormattedMessage
                        id={"register.final.accepttncs.line1"}
                        values={{
                          tnclink: (
                            <a onClick={async() => setTerm(true)} className="mx-1">
                              <FormattedMessage
                                id={"register.final.accepttncs.tnc"}
                              />
                            </a>
                          ),
                          criticallink: (
                            <a href={cisLink.length ? cisLink : CIS_LINK || "javascript:void(0);"} target={cisLink.length ? "_blank" : ""} className="mx-1">
                              <FormattedMessage
                                id={"register.final.accepttncs.critical"}
                              />
                            </a>
                          ),
                          value: product.value || "0",
                          renewal: formatMessage({
                            id: "register.products.renewal.month",
                          }),
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row className="buttons">
            <Col xs={12}>
              <button
                className="vw-button orange sign-me-up"
                disabled={!(tncChecked && acknowledgeChecked)}
                type="button"
                onClick={handleValidateFormCatpcha}
              >
                {formatMessage({id: "buttons.register-submit"})}
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </Panel>
  );
};

export default Final;
