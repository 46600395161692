import React from "react";

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Panel(props) {
  const {className, children, noPaper, ...rest}  = props;
  return (<div className={`vw-reg-panel ${className}`} {...rest}>
    <Container className={"vw-reg-container"}>
      <Row className="vw-reg-row align-items-center">
        <Col xs={12} lg={10} style={{margin: "0 auto"}}>
          <div className={`vw-panel-inner ${noPaper ? "" : "vw-paper"}`}>
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  </div>);
}