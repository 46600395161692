import React from "react";
// import { useAsyncSetState } from "use-async-setstate";
import root from "window-or-global";
import { BrowserRouter, Route } from "react-router-dom";
import Main from "../components/main";
import Header from "../components/section/header";
// import {getFormData} from "../components/manager/form";

import RegisterCoupon from "../controls/register/coupon";
import RegisterPlan from "../controls/register/plan";
import RegisterReview from  "../controls/register/review";
import RegisterUserDetails  from "../controls/register/user-details/index";
import RegisterFinal  from "../controls/register/final";
import RegisterSuccess  from "../controls/register/success";
import RegisterFail from "../controls/register/fail";

import "../style/register.scss";

export default function Register() {
  if(!root.document) {
    return (<React.Fragment/>);
  }
  return (<>
    <Main>
      <BrowserRouter basename="/register">
        <Route exact path="/" component={(props) => {
          return (
            <>
              <Header />
              <RegisterCoupon {...props} />
            </>
          );
        }} />
        <Route exact path="/plan" component={(props) => {
          return (
            <>
              <Header />
              <RegisterPlan {...props} />
            </>
          );
        }} />
        <Route exact path="/review" component={(props) => {
          return (
            <>
              <Header />
              <RegisterReview {...props} />
            </>
          );
        }} />
        <Route path="/user" component={(props) => {
          return (
            <>
              <Header />
              <RegisterUserDetails {...props} />
            </>
          );
        }} />
        <Route exact path="/final" component={(props) => {
          return (
            <>
              <Header />
              <RegisterFinal {...props} />
            </>
          );
        }} />
        <Route exact path="/success" component={(props) => {
          return (
            <>
              <Header />
              <RegisterSuccess {...props} />
            </>
          );
        }} />
        <Route exact path="/fail" component={(props) => {
          return (
            <>
              <Header />
              <RegisterFail {...props} />
            </>
          );
        }} />
      </BrowserRouter>
    </Main>
  </>);
}
